<template>
  <div>
    <BetaWarning />
    <v-container>
      <v-tabs-items v-model="selectedAppBarTab">
        <v-tab-item
          v-for="tab in tabs"
          :value="tab.key"
          :key="tab.key"
          transition="no-transition"
          reverse-transition="no-transition"
        >
          <v-row
            justify="center"
            class="pa-0"
          >
            <v-col
              cols="12"
              class="py-0"
              v-if="tab.key === 'active'"
            >
              <DeliveryMethodInstanceList
                :title="$t('active')"
                :sub-title="$t('listOfActiveDeliveryServices')"
                :delivery-methods="deliveryMethods"
                :delivery-method-instances="activeDeliveryMethodInstances"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="tab.key === 'inactive'"
            >
              <DeliveryMethodInstanceList
                :title="$t('disabled')"
                :sub-title="$t('listOfDisabledDeliveryServices')"
                :delivery-methods="deliveryMethods"
                :delivery-method-instances="disabledDeliveryMethodInstances"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <DeliveryMethodInstanceDialog
        v-if="deliveryMethodInstanceToAdd"
        v-model="deliveryMethodInstanceToAdd"
        @closed="closeDeliveryMethodInstanceDialog"
      />
      <DeliveryMethodsDialog
        v-model="showDeliveryMethodsDialog"
        v-if="showDeliveryMethodsDialog"
        @closed="closeDeliveryMethodsDialog"
        :delivery-methods="deliveryMethods"
        @selectDeliveryMethod="selectDeliveryMethod"
      />
      <v-row v-if="false">
        <v-col cols="12">
          <DeliveryMethodInstanceList
            :title="$t('active')"
            :sub-title="$t('listOfActiveDeliveryServices')"
            :delivery-methods="deliveryMethods"
            :delivery-method-instances="activeDeliveryMethodInstances"
          />
        </v-col>
        <v-col cols="12">
          <DeliveryMethodInstanceList
            :title="$t('disabled')"
            :sub-title="$t('listOfDisabledDeliveryServices')"
            :delivery-methods="deliveryMethods"
            :delivery-method-instances="disabledDeliveryMethodInstances"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import DeliveryMethodInstanceList from "@/components/DeliveryMethodInstanceList"
import BetaWarning from "@/components/common/BetaWarning"
import DeliveryMethodsDialog from "@/components/deliveryMethod/DeliveryMethodsDialog"
import DeliveryMethodInstanceDialog from "@/components/DeliveryMethodInstanceDialog"

export default {
	name: 'DeliveryServices',
	components: {
		DeliveryMethodsDialog,
		BetaWarning,
		DeliveryMethodInstanceList,
		DeliveryMethodInstanceDialog
	},
	beforeMount() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
		this.$store.commit('updateAppBarFab', {
			color: 'pink',
			callback: this.addNewDeliveryMethodInstance
		})
	},
	data: () => ({
		showDeliveryMethodsDialog: false,
		deliveryMethodInstanceToAdd: null
	}),
	watch: {
		deliveryMethodInstances() {
			this.$store.commit('updateAppBarTabs', this.tabs)
		}
	},
	methods: {
		addNewDeliveryMethodInstance() {
			this.showDeliveryMethodsDialog = true
		},
		closeDeliveryMethodsDialog() {
			this.showDeliveryMethodsDialog = false
		},
		closeDeliveryMethodInstanceDialog()  {
			this.deliveryMethodInstanceToAdd = null
		},
		selectDeliveryMethod(method) {

			this.deliveryMethodInstanceToAdd = {
				title:  method.title,
				description: method.description,
				namespace: method.namespace,
				data: {

				}
			}

			this.closeDeliveryMethodsDialog()
		}
	},
	computed: {
		tabs: function() {
			return [
				{
					title: this.$t('active'),
					key: 'active',
					badge: {
						color: 'green',
						content: this.activeDeliveryCount
					}
				},
				{
					title: this.$t('inactive'),
					key: 'inactive',
					badge: {
						color: 'red',
						content: this.disabledDeliveryCount,
					}
				}
			]
		},
		activeDeliveryCount() {
			return this.activeDeliveryMethodInstances !== null ? this.activeDeliveryMethodInstances.length : 0
		},
		disabledDeliveryCount() {
			return this.disabledDeliveryMethodInstances !== null ? this.disabledDeliveryMethodInstances.length : 0
		},
		selectedAppBarTab: function() {
			return this.$store.state.selectedAppBarTab
		},
		deliveryMethodInstances: function() {
			return this.$store.state.deliveryMethodInstances
		},
		activeDeliveryMethodInstances: function() {

			if(this.deliveryMethodInstances === null) {
				return []
			}

			return this.$store.state.deliveryMethodInstances.filter(instance => instance.enabled)
		},
		disabledDeliveryMethodInstances: function() {

			if(this.deliveryMethodInstances === null) {
				return []
			}

			return this.$store.state.deliveryMethodInstances.filter(instance => !instance.enabled)
		},
		deliveryMethods: function() {
			return this.$store.state.deliveryMethods
		}
	},
	created() {
		this.$store.dispatch('getDeliveryServices')
	}
}
</script>
